<template>
    <div class="wrapper loadsPageWrapper">
        <YearWeekSelector v-model:year="selectedYear" v-model:week="selectedWeek" v-model:yearWeek="yearWeek"/>
        {{ carrierID }}
    </div>
</template>

<script>
import YearWeekSelector from '../../components/dispatch/YearWeekSelector.vue'

    export default {
        components: {
            YearWeekSelector
        },
        data() {
            return {
                selectedYear: 2022,
                selectedWeek: 6,
                yearWeek: null
            }
        },
        computed: {
            carrierID() {
                return this.$route.params.id;
            }
        },
        methods: {
            getLoads() {
                console.log("getting loads for carrier");
            }
        },
        watch: {
            yearWeek() {
                this.getLoads();
            }
        }
    }
</script>

<style lang="scss" scoped>

.loadsPageWrapper {
    padding: 20px;
    min-width: 0;
    max-width: 750px;
    margin: 0 auto;
}

</style>